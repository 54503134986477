@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --ion-font-family: 'Montserrat', sans-serif;
  /** primary **/
  --ion-color-primary: #3f1b4e;
  --ion-color-primary-rgb: 63, 27, 78;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #371845;
  --ion-color-primary-tint: #523260;

  /** secondary **/
  --ion-color-secondary: #ffe343;
  --ion-color-secondary-rgb: 255, 227, 67;
  --ion-color-secondary-contrast: #3f1b4e;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0c83b;
  --ion-color-secondary-tint: #ffe656;

  /** tertiary **/
  --ion-color-tertiary: #3dddcf;
  --ion-color-tertiary-rgb: 61, 221, 207;
  --ion-color-tertiary-contrast: #3f1b4e;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #36c2b6;
  --ion-color-tertiary-tint: #50e0d4;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** black **/
  --ion-color-black: #000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #fff;
  --ion-color-black-contrast-rgb: 244, 244 244;
  --ion-color-black-shade: #d7d8da;
  --ion-color-black-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 244, 244, 244;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  --ion-color-transparent: #ffffff00;
  --ion-color-transparent-rgb: 244, 244, 244;
  --ion-color-transparent-contrast: #000;
  --ion-color-transparent-contrast-rgb: 0, 0, 0;
  --ion-color-transparent-shade: #d7d8da;
  --ion-color-transparent-tint: #f5f6f9;
}
