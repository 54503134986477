/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global */
.primary-text {
  color: var(--ion-color-primary);
}

.secondary-text {
  color: var(--ion-color-secondary);
}

.tertiary-text {
  color: var(--ion-color-tertiary);
}

.success-text {
  color: var(--ion-color-success);
}

.danger-text {
  color: var(--ion-color-danger);
}

.medium-text {
  color: var(--ion-color-medium);
}

.light-text {
  color: var(--ion-color-light);
}

.black-text {
  color: var(--ion-color-black);
}

.bold-text {
  font-weight: bold !important;
}

.primary-background {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.secondary-background {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}

.tertiary-background {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}

.primary-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-primary);
}

.secondary-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-secondary);
}

.tertiary-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-tertiary);
}

.success-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-success);
}

.warning-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-warning);
}

.danger-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-danger);
}

.medium-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-medium);
}

.dark-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-dark);
}

.light-shadow {
  box-shadow: 0px 0px 3px var(--ion-color-light);
}

ion-button {
  text-transform: capitalize;
}

ion-toast {
  --button-color: var(--ion-color-secondary);
}

h1.big {
  font-size: 48px !important;
}

h1.medium {
  font-size: 36px !important;
}

h1 {
  font-size: 26px !important;
}

h2 {
  font-size: 20px !important;
}

h3 {
  font-size: 18px !important;
}

h4 {
  font-size: 16px !important;
}

h5 {
  font-size: 14px !important;
}

h6 {
  font-size: 12px !important;
}

p {
  font-size: 10px !important;
}

.pointer {
  cursor: pointer;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

/* Actionsheet */
.actionSheet .action-sheet-container {
  justify-content: center;
  align-items: center;
}

.actionSheet .action-sheet-group {
  --color: var(--ion-color-primary);
  border-radius: 15px;
  width: 95%;
  max-height: 90vh;
}

.actionSheet .action-sheet-title {
  --color: #fff;
  background-color: var(--ion-color-primary);
  font-weight: bold;
}

/* Alert Modal */
.alert-modal .alert-title {
  color: var(--ion-color-primary);
}

.alert-modal .alert-button-group {
  background: var(--ion-color-primary);
}

.alert-modal .alert-button {
  color: #fff;
  font-weight: bold;
}

/* modal de ventas */
.sales-modal {
  --width: 900px;
}
.sales-image-modal {
  --width: 360px;
  --height: 740px;
}
/* Modals */
@media (min-width: 768px) {
  .confirm-modal {
    --border-radius: 20px;
  }
  .sales-modal {
    --border-radius: 20px;
  }
  .premium-iframe-modal {
    --height: 750px;
    --backdrop-opacity: 0.8;
  }
}
@media (min-width: 960px) {
  .sales-image-modal {
    --width: 900px;
    --height: 600px;
    --border-radius: 35px;
  }
}

.onboarding-modal {
  --border-radius: 20px;
  --background: transparent;
}

.onboarding-modal .modal-wrapper {
  box-shadow: none;
  position: absolute;
  right: 10px;
  bottom: 5px;
  width: 30vw;
  min-width: 350px;
  max-width: 380px;
  max-height: 520px;
}

.container-card-button {
  position: absolute;
  bottom: 5px;
}

.onboarding-popover .popover-content {
  --width: 285px;
  left: 0px !important;
  box-shadow: 5px 8px 8px -3px #000000;
}

.onboarding-popover .popover-arrow {
  width: 20px;
  left: 160px;
}

/* tool tip */
[tooltip]::before {
  content: attr(tooltip);
  position: absolute;
  width: 150px;
  height: 60px;
  bottom: -80%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 5px grey;
  font-weight: bold;
  background-color: white;
  border-radius: 10px;
  visibility: hidden;
  z-index: 1000;
}

[tooltip]:hover::before {
  visibility: visible;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.searchbar-input {
  --box-shadow: 0px 0px 3px var(--ion-color-primary);
  --clear-button-color: var(--ion-color-primary);
  --icon-color: var(--ion-color-primary);
  --border-radius: 10px;
}
